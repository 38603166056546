/* ====== WOW ====== */
new WOW().init();

/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
    var offset = 100,
            offset_opacity = 1200,
            scroll_top_duration = 700,
            $back_to_top = $('.cd-top');
    $(window).scroll(function () {
        ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, scroll_top_duration
                );
    });
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/* ====== Slider ====== */
$(window).load(function () {
    $(".flexslider").flexslider({animation: "fade", animationLoop: !0, pauseOnHover: !1, keyboard: !0, slideshowSpeed: 6000});
});